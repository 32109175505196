import request from '@/utils/request'

// 员工工作统计
export function GetEmployeeDayWorks(data) {
  return request({
    url: '/api/app/employee/day-works',
    method: 'get',
    params: data
  })
}

// 员工获取日工作配置
export function GetEmployeeDayWorkConfigs(data) {
  return request({
    url: '/api/app/employee/day-work-configs',
    method: 'get',
    params: data
  })
}

// 更新员工工作配置
export function SetEmployeeDayWorkConfigs(data) {
  return request({
    url: '/api/app/employee/day-work-config?area=' + data.area,
    method: 'put',
    data: data.data
  })
}

// 更新员工工作状态
export function HandleDayWork(data) {
  return request({
    url: '/api/app/employee/handle-day-work',
    method: 'post',
    data
  })
}

// 更新员工工作统计
export function DayWorkStatistics(data) {
  return request({
    url: '/api/app/employee/day-work-statistics',
    method: 'post',
    data
  })
}

// 员工挪车统计
export function GetEmployeeStatistics(data) {
  return request({
    url: '/api/app/move-task/employee-statistics',
    method: 'get',
    params: data
  })
}

// 员工挪车数据
export function GetMoveTask(data) {
  return request({
    url: '/api/app/move-task',
    method: 'get',
    params: data
  })
}

// 处理挪车相关的状态
export function HandleMoveTask(data) {
  return request({
    url: '/api/app/move-task/handle',
    method: 'post',
    data
  })
}

// 审核挪车数据
export function AuditMoveTask(data) {
  return request({
    url: '/api/app/move-task/audit',
    method: 'post',
    data
  })
}

// 获取挪车数据详情
export function GetMoveTaskDetail(data) {
  return request({
    url: `/api/app/move-task/${data.id}`,
    method: 'get',
    params: data
  })
}

// 获取定位数据
export function GetMoveTaskLocations(data) {
  return request({
    url: `/api/app/move-task/locations`,
    method: 'get',
    params: data
  })
}

// 设置客户街道分区配置数据
export function SetCustomerAreaConfig(data) {
  return request({
    url: '/api/app/customer-area/config?area=' + data.area,
    method: 'put',
    data: data.data
  })
}

// 获取客户街道分区配置数据
export function GetCustomerAreaConfig(data) {
  return request({
    url: '/api/app/customer-area/config',
    method: 'get',
    params: data
  })
}

// 获取客户对账统计订单信息
export function GetCustomerAreaOrders(data) {
  return request({
    url: '/api/app/customer-area/orders',
    method: 'get',
    params: data
  })
}

// 行程单
export function createTravel(data) {
  return request({
    url: '/api/app/travel',
    method: 'post',
    data
  })
}

export function resetTravel(data) {
  return request({
    url: `/api/app/travel/${data.id}/reset`,
    method: 'post',
    data
  })
}

export function getTravel(data) {
  return request({
    url: '/api/app/travel',
    method: 'get',
    params: data
  })
}

export function getTravelDetail(data) {
  return request({
    url: '/api/app/travel/details',
    method: 'get',
    params: data
  })
}