<style lang="scss" scoped>
.page-moving-route {
    .c-datetime-range {
        max-width: 256px !important;
    }
    .map-container  {
        position: relative;
        border: 1px solid #eee;
        padding: 0px;
        margin: 0px;
        width: 100%;
        height: 650px;
    }
    .map-screen {
        margin: 0;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99998;
    }
    .total-count {
        display: block;
        position: absolute;
        top: 5px;
        right: 40px;
        z-index: 10;
        background-color: #ffffff;
        padding: 6px 12px;
        border-radius: 2px;
        box-shadow: 0 0 3px rgba(0,0,0,.5);
    }
    .full-screen, .order-mousetools {
        display: block;
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 10;
        background-color: #ffffff;
        padding: 2px;
        border-radius: 2px;
        box-shadow: 0 0 3px rgba(0,0,0,.5);
        cursor: pointer;
        .el-icon-full-screen {
            font-size: 26px;
        }
    }
    .full-screen:hover, .order-mousetools .btn:hover {
        background-color: #f0f0f0;
    }
    .order-mousetools {
        top: 42px;
        right: 5px;
        padding: 0;
        .btn {
            display: block;
            float: right;
            padding: 4px;
            i {
                font-size: 22px;
            }
        }
        .btn-open, .btn-open:hover {
            background: rgba(121, 184, 255, 1);
            i {
                color: #ffffff;
            }
        }
        .btn-actived {
            i {
                color: #79b8ff;
            }
        }
    }
    .cluster-list {
        padding: 10px 12px;
        border-bottom: 1px dashed #eee;
        .cluster-item {
            margin-top: 3px;
        }
    }
    .cluster-list:hover {
        background-color: #f5f5f5;
    }
}
</style>

<template>
    <div class="page-moving-route">
        <div class="toolbars mrgb10">
            <div class="buttons mrgb5">
                <div class="fl">
                    <el-date-picker class="c-datetime-range mrgr5 mrgb5" v-model="selectedDate" type="daterange" size="medium"
                        :picker-options="pickerOptions" :clearable="false"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        align="right">
                    </el-date-picker>
                    <el-time-picker class="c-datetime-range mrgr5 mrgb5" is-range v-model="selectedTime" size="medium" @change="changeTime"
                        range-separator="至" :default-value="['1970-01-01 00:00:00', '1970-01-01 23:59:59']"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        placeholder="选择时间范围">
                    </el-time-picker>
                    <el-button type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
                    <el-tooltip effect="dark" content="注：拖动时间可以快捷查看该时间段的数据。" placement="top">
                        <el-tag class="mrgl5" type="warning" size="medium"><i class="el-icon-question f16"></i></el-tag>
                    </el-tooltip>
                </div>
                <div class="fr">
                    <el-tooltip class="item" effect="dark" content="在系统配置中更改了挪车配置后，需手动更新下相关数据" placement="top-start">
                        <el-button type="danger" size="medium" @click="_handle()"><i class="fa fa-refresh"></i> 更新挪车数据</el-button>
                    </el-tooltip>
                </div>
                <div class="clearb"></div>
                <div>
                    <el-button-group>
                        <el-button type="primary" :plain="!(cueSelectTimers.length === 2 && time >= cueSelectTimers[0] && time <= cueSelectTimers[1])" size="medium"
                            v-for="(item, time) in 24" :key="item" @click="selectTime(time)"
                            @mousedown.native="mouseSelectTime(time, 0)" @mouseup.native="mouseSelectTime(time, 1)">{{ time }}时</el-button>
                    </el-button-group>
                </div>
            </div>
        </div>
        <div class="map-container" :class="isScreen ? 'map-screen' : ''" id='container'>
            <span class="total-count col_primary">
                挪车量：{{ moveDatas.length }}，
                <img class="mrgr2" :src="iconImages[0]" width="8" height="8" />用车量：{{ orderJson0.length }}，
                <img class="mrgr2" :src="iconImages[1]" width="8" height="8" />还车量：{{ orderJson1.length }}
            </span>
            <span class="full-screen" :title="!isScreen ? '全屏' : '还原'" @click="isScreen = !isScreen">
                <i class="el-icon-full-screen"></i>
            </span>
            <span class="order-mousetools">
                <el-tooltip :content="isChooseOpen ? '结束框选' : '开始框选'" placement="top-end">
                    <span class="btn" :class="isChooseOpen ? 'btn-open' : ''"
                        @click="chooseSwitch()"><i :class="isChooseOpen ? 'el-icon-crop' : 'el-icon-crop'"></i></span>
                </el-tooltip>
                <template v-if="isChooseOpen">
                    <el-tooltip content="矩形框选" placement="top-end">
                        <span class="btn" :class="chooseType === chooseTypeArray[0] ? 'btn-actived' : ''"
                            @click="chooseSelectType(chooseTypeArray[0])"><i class="el-icon-menu"></i></span>
                    </el-tooltip>
                    <el-tooltip content="圆形框选" placement="top-end">
                        <span class="btn" :class="chooseType === chooseTypeArray[1] ? 'btn-actived' : ''"
                            @click="chooseSelectType(chooseTypeArray[1])"><i class="el-icon-bangzhu"></i></span>
                    </el-tooltip>
                </template>
            </span>
        </div>
    </div>
</template>

<script>
import * as funMaintenance from "@/api/maintenance"
import * as funReport from "@/api/report"
import moment from 'moment'

let dataVars = {
    map: null, // 地图
    polygonMap: null,
    labelsLayer0: null,
    labelsLayer1: null,
    parkingMarker: [],
    parkingLines: [],
    mouseTool: null, // 鼠标绘制工具
    polygonObjs: [],
    textMarkers: [],
    ruleTool: null
}
export default {
    name: "pageMovingRoute",
    data() {
        let that = this
        return {
            pageHeader: {
                desc: "挪车前后相关点位，路线等。"
            },
            listQuery: {
                area: "",
                timeType: 0,
                beginDate: "",
                endDate: "",
                beginTime: "",
                endTime: ""
            },
            cueSelectTimers: [],
            curMouseDownTime: null,
            isScreen: false,
            selectedDate: [moment().subtract(1, "d").format("YYYY-MM-DD"), moment().subtract(1, "d").format("YYYY-MM-DD")],
            selectedTime: [],
            pickerOptions: {
                shortcuts: [
                    {
                        text: "昨天",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "days")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一周",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "weeks")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近三月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 3, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近半年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 6, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "years")
                            that.setQueryTime(timeArr)
                        }
                    }
                ]
            },
            coverPaths: [], // 服务区多边形点位
            orderJson0: [],
            orderJson1: [],
            parkings: [],
            moveDatas: [],
            futureOrderInterval: 2, // 需要查看未来几小时的订单
            futureOrder0: [],
            futureOrder1: [],
            isShowTimeType0: true,
            isShowTimeType1: true,
            isShowParking: true,
            isShowParkingLine: true,
            isShowMovingRoute: true,
            isChooseOpen: false, // 框选开关
            chooseTypeArray: [1, 2],
            chooseType: null,
            iconImages: ["/static/images/map/mass2.png", "/static/images/map/mass1.png", "/static/images/map/poi-marker-default.png"]
        }
    },
    watch: {
        "$store.getters.serviceArea"(v) {
            this.mxIsSameActiveTabWithRoute(() => {
                this.getCurrentAreaData()
                this.setPolygonContains()
                this.initLoadData()
            })
        }
    },
    async mounted() {
        this.setSysPageHeader(this.pageHeader)
        this.getCurrentAreaData()
        await this.initMap()
        await this.initLoadData()
        this.loadKeyDown()
        this.loadKeyUp()
    },
    unmounted() {
        dataVars.map?.destroy()
        document.removeEventListener("keydown")
        document.removeEventListener("keyup")
    },
    methods: {
        // 重写本页的时间范围的快捷筛选，设置时间
        setPickerTime(picker, spaceTime, dateStr, isStartOf) {
            let timeArr = []
            let startTime = null
            let endTime = moment().subtract(1, "d").format("YYYY-MM-DD 23:59:59")
            startTime = moment().subtract(spaceTime, dateStr).format("YYYY-MM-DD 00:00:00")
            startTime = moment(startTime).format("YYYY-MM-DD 00:00:00")
            timeArr = [startTime, endTime]
            if (picker) {
                picker.$emit('pick', timeArr);
            }
            return timeArr
        },
        setQueryTime(timeArr) {
            this.listQuery.beginDate = moment(timeArr[0]).format("YYYY-MM-DD")
            this.listQuery.endDate = moment(timeArr[1]).format("YYYY-MM-DD")
        },
        setSelectedTime() {
            let psDate = ""
            let psTime = ""
            let peDate = ""
            let peTime = ""
            // 日期段
            if (this.selectedDate && this.selectedDate[0] && moment(this.selectedDate[0]).isValid()) {
                psDate = moment(this.selectedDate[0]).format("YYYY-MM-DD")
                // 时间段
                if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
                    psTime = moment(this.selectedTime[0]).format("HH:mm:ss")
                } else {
                    psTime = moment(this.selectedDate[0]).format("00:00:00")
                }
            }
            if (this.selectedDate && this.selectedDate[1] && moment(this.selectedDate[1]).isValid()) {
                peDate = moment(this.selectedDate[1]).format("YYYY-MM-DD")
                // 时间段
                if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
                    peTime = moment(this.selectedTime[1]).format("HH:mm:ss")
                } else {
                    peTime = moment(this.selectedDate[1]).format("23:59:59")
                }
            }
            this.listQuery.beginDate = psDate
            this.listQuery.endDate = peDate
            this.selectedDate = [this.listQuery.beginDate, this.listQuery.endDate]
            this.listQuery.beginTime = psTime
            this.listQuery.endTime = peTime
            this.selectedTime = [`${this.listQuery.beginDate} ${this.listQuery.beginTime}`, `${this.listQuery.endDate} ${this.listQuery.endTime}`]
        },
        async initMap() {
            await window.$common.loadGaoDeMap({ plugins: ["AMap.MouseTool"] }, (Amap) => {
                // 生成地图
                this.initCreateMap()
                // 设置服务区
                this.setPolygonContains()
            })
        },
        // 生成地图
        initCreateMap() {
            let zoom = 16
            dataVars.map = new AMap.Map("container", {  //设置地图容器id
                viewMode: "2D",    //是否为3D地图模式
                zoom: zoom,           //初始化地图级别
                center: [104.065861,30.657401], //初始化地图中心点位置
                // features: ["bg", "road"],
                // showLabel: false,
                // scrollWheel: false
            })
            dataVars.map.addControl(new AMap.ToolBar())
            dataVars.map.addControl(new AMap.Scale())
            // 创建鼠标绘制
            this.setMouseTools()
            this.moveMap()
            // 右键
            this.setContextMenu()
        },
        setContextMenu() {
            // 创建右键
            let contextMenu = new AMap.ContextMenu()
            contextMenu.addItem("<i class='el-icon-delete'></i> 清空框选", (e) => {
                this.clearMouseTool()
                contextMenu.close()
            })
            contextMenu.addItem("<i class='el-icon-menu'></i> 矩形框选", (e) => {
                this.chooseType = this.chooseTypeArray[0]
                this.chooseSwitch(true)
                contextMenu.close()
            })
            contextMenu.addItem("<i class='el-icon-bangzhu'></i> 圆形框选", (e) => {
                this.chooseType = this.chooseTypeArray[1]
                this.chooseSwitch(true)
                contextMenu.close()
            })
            contextMenu.addItem("<i class='el-icon-lock'></i> 结束框选", (e) => {
                this.chooseSwitch(false)
                contextMenu.close()
            })
            contextMenu.addItem("<i class='el-icon-c-scale-to-original'></i> 开始/清空测距", (e) => {
                if (!dataVars.ruleTool) {
                    dataVars.ruleTool = new AMap.MouseTool(dataVars.map)
                    dataVars.ruleTool.rule()
                } else {
                    dataVars.ruleTool.close(true)
                    dataVars.ruleTool = null
                }
                contextMenu.close()
            })
            contextMenu.addItem("<i class='el-icon-full-screen'></i> 全屏/取消全屏", (e) => {
                this.isScreen = !this.isScreen
                contextMenu.close()
            })
            contextMenu.addItem("<i class='el-icon-switch-button'></i> 关闭右键菜单", (e) => {
                contextMenu.close()
            })
            dataVars.map.on('rightclick', (e) => {
                contextMenu.open(dataVars.map, e.lnglat)
            })
        },
        // 设置多边形数据
        setPolygonContains() {
            dataVars.polygonMap && dataVars.map.remove(dataVars.polygonMap)
            let path = this.coverPaths
            if (path.length <= 0) {
                return false
            }
            // 设置服务区范围
            dataVars.polygonMap = new AMap.Polygon({
                bubble: true,
                path: path,
                strokeColor: "#409EFF", 
                strokeWeight: 3,
                strokeOpacity: 0.5,
                fillOpacity: 0.05,
                strokeStyle: 'dashed',
                strokeDasharray: [5, 5]
            })
            dataVars.map.add(dataVars.polygonMap)
            // 计算服务区中心点
            let lngTotal = 0
            let latTotal = 0
            for (let i in path) {
                let arr = path[i]
                lngTotal += Number(arr[0])
                latTotal += Number(arr[1])
            }
            let curLnglat = [lngTotal / path.length, latTotal / path.length]
            this.moveToCenter({ lnglat: curLnglat })
        },
        // 地图移动，显示隐藏海量标记
        moveMap() {
            let _setTimeout = null
            let isMapMoving = false
            // 停止变动，显示海量点
            let stopMove = () => {
                _setTimeout && clearTimeout(_setTimeout)
                _setTimeout = setTimeout(() => {
                    if (isMapMoving) {
                        this.changeMapLabel(true)
                        isMapMoving = false
                    }
                }, 2000)
            }
            // 地图移动
            dataVars.map.on("mapmove", () => {
                isMapMoving = true
                _setTimeout && clearTimeout(_setTimeout)
                _setTimeout = null
                this.changeMapLabel(false)
            })
            dataVars.map.on("moveend", () => {
                stopMove()
            })
            // 地图缩放
            dataVars.map.on("zoomstart", () => {
                isMapMoving = true
                _setTimeout && clearTimeout(_setTimeout)
                _setTimeout = null
                this.changeMapLabel(false)
            })
            dataVars.map.on("zoomend", () => {
                stopMove()
            })
        },
        // 显示隐藏海量点
        changeMapLabel(type) {
            if (type) {
                dataVars.map.setFeatures(["bg", "point", "road"])
            } else {
                dataVars.map.setFeatures(["bg", "road"])
            }
        },
        // 加载数据
        async initLoadData() {
            this.clearAllMarkers()
            await this.getPackings()
            await this._search()
        },
        // 站点数据
        async getPackings() {
            this.parkings = []
            let area = this.$store.getters.serviceArea || ""
            await funReport.GetParkingLocations({ area }).then(response => {
                this.parkings = response
            })
            this.setPackingData()
        },
        // 设置站点信息
        setPackingData() {
            this.clearParking()
            if (this.isShowParking) {
                let markers = []
                let datas = JSON.parse(JSON.stringify(this.parkings))
                for (let i in datas) {
                    let item = datas[i]
                    let marker = new AMap.Polygon({
                        zIndex: 100,
                        bubble: true,
                        path: item.pointList,
                        strokeWeight: 1,
                        fillColor: "#1791fc", // "#f56c6c",
                        fillOpacity: 0.2,
                        strokeColor: "#1791fc", // "#f56c6c"
                        extData: item
                    })
                    marker.on("click", (ev) => {
                        let packItem = ev.target.getExtData()
                        funReport.GetParkingById({ id: packItem.id }).then(res => {
                            let content = `<div>
                                                <div class="txtClose" style="position: absolute; z-index: 100; top: -6px; right: -6px;"><i class="el-icon-error f16 col6"></i></div>
                                                <div class="f13" style="padding: 5px 8px 5px 5px;">
                                                    <div>站点名称：${res.name} <i class="el-icon-document-copy col_primary"></i></div>
                                                </div>
                                            </div>`
                            this.mxCommonMapTextInfo(dataVars.map, {
                                text: content,
                                position: [res.centerLng, res.centerLat]
                            }, () => {
                                try {
                                    window.navigator.clipboard.writeText(res.name).then(() => {
                                        this.successMsg("站点名称复制成功!")
                                    })
                                } catch(ex) {
                                    this.alert(res.name, "手动复制站点", {
                                        customClass: "wordbreakba"
                                    })
                                }
                            })
                        })
                    })
                    dataVars.parkingMarker.push(marker)
                    dataVars.map.add(marker)
                }
            }
        },
        setPackingData_bak() {
            this.clearParking()
            if (this.isShowParking) {
                dataVars.parkingMarker = new AMap.LabelsLayer({
                    zooms: [3, 20],
                    zIndex: 1000,
                    collision: false
                })
                dataVars.map.add(dataVars.parkingMarker)
                let markers = []
                let datas = JSON.parse(JSON.stringify(this.parkings))
                for (let i in datas) {
                    let item = datas[i]
                    let temp = {
                        position: [item.centerLng, item.centerLat],
                        icon: {
                            type: "image",
                            image: this.iconImages[2],
                            size: [10, 16.5],
                            anchor: 'bottom-center'
                        }
                    }
                    let labelMarker = new AMap.LabelMarker(temp)
                    markers.push(labelMarker)
                }
                dataVars.parkingMarker.add(markers)
            }
        },
        // 挪车数据
        async getMoveTaskData() {
            this.moveDatas = []
            await funMaintenance.GetMoveTaskLocations(this.listQuery).then(response => {
                this.moveDatas = response
            })
            this.setParkingLine()
        },
        setParkingLine() {
            this.clearParkingLine()
            let datas = JSON.parse(JSON.stringify(this.moveDatas))
            for (let i in datas) {
                let item = datas[i]
                if (!item.start_lng || !item.start_lat || !item.end_lng || !item.end_lat) {
                    continue
                }
                let arr = []
                let random = Math.random() * (1 - 0.5) + 0.5
                let baseLng = 0
                let baseLat = 0
                // 结束经度 > 开始经度，结束纬度 > 开始纬度
                if (item.end_lng >= item.start_lng && item.end_lat > item.start_lat) {
                    baseLng = -0.002
                    baseLat = 0.003
                } else if (item.end_lng >= item.start_lng && item.end_lat < item.start_lat) {
                    baseLng = 0.002
                    baseLat = 0.003
                } else if (item.end_lng <= item.start_lng && item.end_lat < item.start_lat) {
                    baseLng = 0.002
                    baseLat = -0.003
                } else if (item.end_lng <= item.start_lng && item.end_lat > item.start_lat) {
                    baseLng = -0.002
                    baseLat = -0.003
                }
                let ctLng = (item.start_lng + item.end_lng) / 2 + baseLng * random
                let ctLat = (item.start_lat + item.end_lat) / 2 + baseLat * random
                arr = [
                    [item.start_lng, item.start_lat],
                    [ctLng, ctLat, item.end_lng, item.end_lat]
                ]
                let fillColors = ["#1791fc", "#05ad80", "#9907f1", "#e0992d", "#2de0bc"]
                let parkLine = new AMap.BezierCurve({
                    bubble: true,
                    path: arr,            // 设置线覆盖物路径
                    strokeColor: fillColors[Math.floor(Math.random() * 5)],   // 线颜色
                    strokeOpacity: 0.5,         // 线透明度
                    strokeWeight: 1.5,          // 线宽
                    strokeStyle: 'solid',     // 线样式
                    // geodesic: true,
                    showDir: true
                })
                dataVars.parkingLines.push(parkLine)
                dataVars.map.add(parkLine)
            }
            // dataVars.map.add(dataVars.parkingLines)

        },
        _handle() {
            this.setSelectedTime()
            let param = {
                area: this.listQuery.area,
                beginDate: this.listQuery.beginDate,
                endDate: this.listQuery.endDate,
                keywords: this.listQuery.keywords
            }
            window.$common.fullLoading()
            funMaintenance.HandleMoveTask(param).then(res => {
                this._search()
            })
        },
        async getDataList() {
            // 清空数据
            this.orderJson0 = []
            this.orderJson1 = []
            this.futureOrder0 = []
            this.futureOrder1 = []
            this.setSelectedTime()
            window.$common.fullLoading()
            this.listQuery.area = this.$store.getters.serviceArea || ""
            let params = JSON.parse(JSON.stringify(this.listQuery))
            params.timeType = 0
            await funReport.GetOrderLocationOnly(params).then(response => {
                this.orderJson0 = response
            })
            params.timeType = 1
            await funReport.GetOrderLocationOnly(params).then(response => {
                this.orderJson1 = response
            })
            this.setPointData0()
            this.setPointData1()
            await this.getMoveTaskData()
            this.refreshTextMarker()
            window.$common.closeFullLoading()
        },
        // 开锁订单点位
        setPointData0() {
            this.clearLabelsLayer0()
            if (this.isShowTimeType0) {
                dataVars.labelsLayer0 = new AMap.LabelsLayer({
                    collision: false
                })
                dataVars.map.add(dataVars.labelsLayer0)
                let markers = []
                let tempdatas = []
                let datas = JSON.parse(JSON.stringify(this.orderJson0))
                for (let i in datas) {
                    let item = datas[i]
                    let temp = {
                        position: [item.lng, item.lat],
                        icon: {
                            type: "image",
                            image: this.iconImages[0],
                            size: [5, 5],
                            anchor: 'center'
                        }
                    }
                    let labelMarker = new AMap.LabelMarker(temp)
                    markers.push(labelMarker)
                    tempdatas.push([item.lng, item.lat])
                }
                //  document.write(JSON.stringify(tempdatas))
                dataVars.labelsLayer0.add(markers)
            }
        },
        // 关锁订单点位
        setPointData1() {
            this.clearLabelsLayer1()
            if (this.isShowTimeType1) {
                dataVars.labelsLayer1 = new AMap.LabelsLayer({
                    collision: false
                })
                dataVars.map.add(dataVars.labelsLayer1)
                let markers = []
                let datas = JSON.parse(JSON.stringify(this.orderJson1))
                for (let i in datas) {
                    let item = datas[i]
                    let temp = {
                        position: [item.lng, item.lat],
                        icon: {
                            type: "image",
                            image: this.iconImages[1],
                            size: [5, 5],
                            anchor: 'center'
                        }
                    }
                    let labelMarker = new AMap.LabelMarker(temp)
                    markers.push(labelMarker)
                }
                dataVars.labelsLayer1.add(markers)
            }
        },
        // 获取服务器的定位path信息
        getCurrentAreaData() {
            let areaName = this.$store.getters.serviceArea || ""
            let areaList = this.$store.getters.serviceAreas || []
            let areaItem = areaList.find(x => { return x.name === areaName })
            if (areaItem) {
                this.coverPaths = areaItem.pointList || []
            }
        },
        // 时间段选择
        changeTime(arr) {
            this.cueSelectTimers = []
            if (arr) {
                this.listQuery.beginTime = moment(arr[0]).format("HH:mm:ss")
                this.listQuery.endTime = moment(arr[1]).format("HH:mm:ss")
                let sTime = moment(arr[0]).format("HH:00:00")
                let eTime = moment(arr[1]).format("HH:59:59")
                // 时间相同，配置选中效果
                if (this.listQuery.beginTime === sTime && this.listQuery.endTime === eTime) {
                    this.cueSelectTimers = [moment(arr[0]).format("H"), moment(arr[1]).format("H")]
                }
                this._search()
            }
        },
        // 快捷时间段选择
        selectTime(time) {
            this.cueSelectTimers = [time, time]
            let psTime = ""
            let peTime = ""
            let hour = time < 10 ? ("0" + time ) : time
            if (this.selectedDate && this.selectedDate[0] && moment(this.selectedDate[0]).isValid()) {
                psTime = moment(this.selectedDate[0]).format("YYYY-MM-DD " + hour + ":00:00")
            }
            if (this.selectedDate && this.selectedDate[1] && moment(this.selectedDate[1]).isValid()) {
                peTime = moment(this.selectedDate[1]).format("YYYY-MM-DD " + hour + ":59:59")
            }
            this.selectedTime = [psTime, peTime]
            this._search()
        },
        mouseSelectTime(time, type) {
            if (type === 0) {
                // 鼠标按下
                this.curMouseDownTime = time
            } else if (type === 1) {
                // 鼠标放开
                if (this.curMouseDownTime !== time) {
                    let psTime = ""
                    let peTime = ""
                    let sHour = null
                    let eHour = null
                    if (time > this.curMouseDownTime) {
                        this.cueSelectTimers = [this.curMouseDownTime, time]
                        sHour = this.curMouseDownTime < 10 ? ("0" + this.curMouseDownTime ) : this.curMouseDownTime
                        eHour = time < 10 ? ("0" + time ) : time
                    } else if (time < this.curMouseDownTime) {
                        this.cueSelectTimers = [time, this.curMouseDownTime]
                        sHour = time < 10 ? ("0" + time ) : time
                        eHour = this.curMouseDownTime < 10 ? ("0" + this.curMouseDownTime ) : this.curMouseDownTime
                    }
                    if (this.selectedDate && this.selectedDate[0] && moment(this.selectedDate[0]).isValid()) {
                        psTime = moment(this.selectedDate[0]).format("YYYY-MM-DD " + sHour + ":00:00")
                    }
                    if (this.selectedDate && this.selectedDate[1] && moment(this.selectedDate[1]).isValid()) {
                        peTime = moment(this.selectedDate[1]).format("YYYY-MM-DD " + eHour + ":59:59")
                        peTime = moment(peTime).subtract(1, "hours")
                    }
                    this.selectedTime = [psTime, peTime]
                    this._search()
                }
            }
        },
        async _search() {
            await this.getDataList()
        },
        // 框选地图显示对应的数据
        /************鼠标绘制选点************/
        // 创建鼠标绘制
        setMouseTools() {
            dataVars.map.on("mousedown", (e) => {
                if (e.originEvent.button === 2) {
                    // this.chooseSwitch()
                }
            })
            dataVars.mouseTool = new AMap.MouseTool(dataVars.map)
            dataVars.mouseTool.on("draw", async (event) => {
                // 面积必须大于0
                if (event.obj.getArea && event.obj.getArea() > 0) {
                    this.setPolygons(event.obj)
                    await this.setTextMarkers(event.obj)
                    dataVars.map.remove(event.obj)
                }
            })
        },
        setPolygons(obj) {
            let polygon = null
            if (this.chooseType === this.chooseTypeArray[0]) {
                // 矩形
                polygon = new AMap.Rectangle(obj.getOptions())
            } else if (this.chooseType === this.chooseTypeArray[1]) {
                // 圆形
                polygon = new AMap.Circle(obj.getOptions())
            }
            dataVars.polygonObjs.push(polygon)
            dataVars.map.add(polygon)
        },
        async setTextMarkers(obj) {
            let content = await this.getTextMarkerContent(obj)
            let curLnglat = obj.getCenter()
            let text = new AMap.Text({
                bubble: true,
                topWhenClick: true,
                text: content,
                anchor:"center",
                position: [curLnglat.lng, curLnglat.lat]
            })
            dataVars.textMarkers.push(text)
            dataVars.map.add(text)
            // 监听判断
            text.on("click", (event) => {
                let txt = event.target.dom.querySelector(".txtClose")
                let handleClick = (ev) => {
                    let isCloseClick = txt.contains(ev.target)
                    if (isCloseClick) {
                        let index = dataVars.textMarkers.findIndex(x => x._amap_id === text._amap_id)
                        dataVars.map.remove(dataVars.polygonObjs[index])
                        dataVars.polygonObjs.splice(index, 1)
                        dataVars.map.remove(text)
                        dataVars.textMarkers.splice(index, 1)
                    }
                    document.removeEventListener("click", handleClick)
                }
                document.addEventListener("click", handleClick)
            })
        },
        async getTextMarkerContent(obj, text) {
            let moveInCount = 0
            let moveOutCount = 0
            for (let i in this.moveDatas) {
                let item = this.moveDatas[i]
                if (obj.contains([item.start_lng, item.start_lat])) {
                    moveOutCount++
                }
                if (obj.contains([item.end_lng, item.end_lat])) {
                    console.log(item)
                    moveInCount++
                }
            }
            let useCount = 0
            for (let i in this.orderJson0) {
                let item = this.orderJson0[i]
                if (obj.contains([item.lng, item.lat])) {
                    useCount++
                }
            }
            let backCount = 0
            for (let i in this.orderJson1) {
                let item = this.orderJson1[i]
                if (obj.contains([item.lng, item.lat])) {
                    backCount++
                }
            }
            let params = JSON.parse(JSON.stringify(this.listQuery))
            let baseFutureTime = moment(moment().format(`${this.listQuery.endDate} ${this.listQuery.endTime}`)).add(2, "h")
            params.endDate = moment(baseFutureTime).format("YYYY-MM-DD")
            params.beginTime = this.listQuery.endTime
            params.endTime = moment(baseFutureTime).format("HH:mm:ss")
            // 加载未来用车订单
            if (!this.futureOrder0 || this.futureOrder0.length <= 0) {
                params.timeType = 0
                window.$common.fullLoading()
                await funReport.GetOrderLocationOnly(params).then(response => {
                    this.futureOrder0 = response
                })
            }
            let futureUseCount = 0
            for (let i in this.futureOrder0) {
                let item = this.futureOrder0[i]
                if (obj.contains([item.lng, item.lat])) {
                    futureUseCount++
                }
            }
            // 加载未来还车订单
            if (!this.futureOrder1 || this.futureOrder1.length <= 0) {
                params.timeType = 1
                await funReport.GetOrderLocationOnly(params).then(response => {
                    this.futureOrder1 = response
                })
            }
            let futureBackCount = 0
            for (let i in this.futureOrder1) {
                let item = this.futureOrder1[i]
                if (obj.contains([item.lng, item.lat])) {
                    futureBackCount++
                }
            }
            window.$common.closeFullLoading()
            let content = `<div>
                            <div class="txtClose" style="position: absolute; z-index: 100; top: -6px; right: -6px;"><i class="el-icon-error f16 col6"></i></div>
                            <div class="f12" style="padding: 0 5px 5px;">
                                <div>挪入量：${moveInCount}</div>
                                <div>挪出量：${moveOutCount}</div>
                                <div>用车量：${useCount}</div>
                                <div>还车量：${backCount}</div>
                                <div class="col_info mrgt5 mrgb5">--后${this.futureOrderInterval}小时--</div>
                                <div>用车量：${futureUseCount}</div>
                                <div>还车量：${futureBackCount}</div>
                            </div>
                          </div>`
            // 如果是刷新数据，直接更新
            if (text) {
                text.setText(content)
            }
            return content
        },
        // 刷新时重新变更数据
        async refreshTextMarker() {
            for(let i in dataVars.textMarkers) {
                await this.getTextMarkerContent(dataVars.polygonObjs[i], dataVars.textMarkers[i])
            }
        },
        chooseSwitch(isOpen) {
            if (isOpen !== undefined) {
                this.isChooseOpen = isOpen
            } else {
                this.isChooseOpen = !this.isChooseOpen
            }
            if (!this.isChooseOpen) {
                dataVars.mouseTool && dataVars.mouseTool.close(true)
            } else {
                this.chooseSelectType(this.chooseType)
            }
        },
        chooseSelectType(type) {
            // 先移除之前的选框对象
            this.chooseType = type || this.chooseTypeArray[0]
            let options = {
                strokeColor: "#1791fc", //轮廓线颜色
                strokeOpacity: 0.3, //轮廓线透明度
                strokeWeight: 2, //轮廓线宽度
                fillColor: "#1791fc", //多边形填充颜色
                fillOpacity: 0.6, //多边形填充透明度
                strokeStyle: "solid", //线样式还支持 'dashed'
            }
            if (this.chooseType === this.chooseTypeArray[0]) {
                dataVars.mouseTool.rectangle(options)
            } else if (this.chooseType === this.chooseTypeArray[1]) {
                dataVars.mouseTool.circle(options)
            }
        },
        loadKeyDown() {
            document.addEventListener("keydown", (obj) => {
                let key = obj.key
                if (key === "Control") {
                    this.chooseSwitch(true)
                }
            })
        },
        loadKeyUp() {
            document.addEventListener("keyup", (obj) => {
                let key = obj.key
                if (key === "Control") {
                    this.chooseSwitch(false)
                }
            })
        },
        clearAllMarkers() {
            this.clearLabelsLayer0()
            this.clearLabelsLayer1()
            this.clearParking()
            this.clearParkingLine()
        },
        clearLabelsLayer0() {
            dataVars.labelsLayer0 && dataVars.map.remove(dataVars.labelsLayer0)
            dataVars.labelsLayer0 = null
        },
        clearLabelsLayer1() {
            dataVars.labelsLayer1 && dataVars.map.remove(dataVars.labelsLayer1)
            dataVars.labelsLayer1 = null
        },
        clearParking() {
            for (let i in dataVars.parkingMarker) {
                dataVars.map.remove(dataVars.parkingMarker[i])
            }
            dataVars.parkingMarker = []
        },
        clearParkingLine() {
            for (let i in dataVars.parkingLines) {
                dataVars.map.remove(dataVars.parkingLines[i])
            }
            dataVars.parkingLines = []
        },
        clearMouseTool() {
            this.clearPolygonMarkers()
            this.clearTextMarkers()
            dataVars.mouseTool && dataVars.mouseTool.close(true)
        },
        clearPolygonMarkers() {
            for (let i in dataVars.polygonObjs) {
                dataVars.map.remove(dataVars.polygonObjs[i])
            }
            dataVars.polygonObjs = []
        },
        clearTextMarkers() {
            for (let i in dataVars.textMarkers) {
                dataVars.map.remove(dataVars.textMarkers[i])
            }
            dataVars.textMarkers = []
        },
        moveToCenter(item) {
            dataVars.map.setCenter(item.lnglat)
        }
    }
}
</script>